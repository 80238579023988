.text-medium-emphasie{
  font-family: 'Urbanist' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  /* Primary/Neutral/900 */
  color: #192252;

}
.submitforgotlogin {
  /* display: flex; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 48px;
  margin-left: 0;
  /* left: 62.85%; */
  /* right: 9.49%; */
  /* top: calc(50% - 24px + 146px); */
  color: white;
  width: 100%;
  background: #006eff;
  border-radius: 8px;
}

.card-adjust {
  height: 90vh;
}