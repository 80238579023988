
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

@import "@coreui/coreui/scss/coreui";

@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

@import "custom";

@import "login";
@import "resetpassword";
@import "forgotpassword";
@import "verification";

.sidebar-nav .nav-title {
  color: #000;
}

element.style {
  padding: 0px;
}







body {
  margin: 0;

  font-family: 'Lato' !important;
  font-weight: 500;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

user agent stylesheet div {
  display: block;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.sidebar {
  color: #000;
}

.sidebar-nav .nav-link {
  font-size: 14px;
  color: white;
  font-weight: 900;
}

.sidebar-nav .nav-link.active {
  color: white;
  background: black;
}

.sidebar-brand {
  display: flex;
  flex: 0 0 160px;
  align-items: center;
  justify-content: center;
  color: var(--cui-sidebar-brand-color, rgba(255, 255, 255, 0.87));
  background: white;
}

.sidebar-nav .nav-icon {
  flex: 0 0 4rem;
  height: 1.25rem;
  font-size: 1.25rem;
  text-align: center;
  fill: currentColor;
  transition: inherit;
}

.sidebar-toggler {
  display: flex;
  flex: 0 0 3rem;
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  background-color: #147b7e;
  border: 0;
}

.me-2 {

  width: 200px;
  height: 30px;
  left: 365px;
  top: 91px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.64);
  border-radius: 8px;
}

.searchbutton {
  width: 30px;
  height: 30px;
}

.cddropdown {
  width: 181px;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
  margin-top: -6px;
}

.button {
 
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: var(--cui-dropdown-link-color, #4f5d73);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.tablehead {
  background-color: #e5e5e5;
  font-family: Poppins;
}

.row {
  --cui-gutter-x: 1.5rem;
  --cui-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: calc(-1 * var(--cui-gutter-y));
  margin-right: calc(-0.5 * var(--cui-gutter-x));
  margin-left: calc(-0.5 * var(--cui-gutter-x));
}

.breadcrumb {
  font-family: "Mulish";
  font-style: normal;

  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.3px;

  color: #252733;
}



.search-box {
  position: absolute;
  top: 50%;
  left: 50%;

  background: white;
  height: 40px;
  border-radius: 40px;
  padding: 10px;
}

.search-btn {
  color: #4097ff;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2f3640;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.search-txt {
  border: none;
  background: white;
  outline: none;
  float: left;
  padding: 0;
  color: black;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 200px;
}

.search-icon {
  position: absolute;
  left: 8%;
  top: 20%;
}

.form-control {
  display: block;
  width: 81%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");


.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.search {
  display: inline-block;
}

.search input {
  width: 250px;
  height: 32px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input {
  text-indent: 32px;

  width: 11rem;
}

.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

.search .fa-search {
  left: auto;
  right: 13px;
  top: 8px;
}

.col-2 {
  margin-right: 39px;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-8 {
  flex: 0 0 auto;
  width: 58.666667%;
}

.removepic {
  box-sizing: border-box;

  width: 104px;
  height: 134px;

  background: rgba(196, 196, 196, 0.12);
  border: 1px solid #848fac;
  border-radius: 10px;
}

.removebuttonbox {
  width: 206px;
  height: 36px;

  background: #ffffff;
  border: 2px solid #848fac;
  border-radius: 5px;
}

.removebuttton {

  width: 212px;
  height: 30px;

  background: darkcyan;
  border: 2px solid #848fac;
  border-radius: 5px;
}

.col-6 {
  padding-left: 8rem;
  padding-right: 8rem;
  flex: 0 0 auto;
  width: 50%;
}

.headerpic {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #abb2c0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.modalheader {
  margin-top: 0rem;
  display: flex;
  border: 1px solid grey;
}

.subscriptionbox {
  box-sizing: border-box;

 
  width: 300px;
  height: 300px;
  margin-left: 2rem;
  

  background: #ffffff;
  border: 2px solid #abb2c0;
  border-radius: 3px;
}

.box_content {
  font-size: 12px;
  margin-left: 6px;
}

.box_content1 {
  font-size: 11px;
  margin-left: 6px;
  font-weight: 600;
}

.box_content2 {
  font-size: 11px;
  margin-right: 0px;
  margin-left: 6px;
  font-weight: 600;
}

.updatebutton {
  width: 233px;
  height: 40px;
  position: relative;
  left: 46%;
 
  color: white;
  background: darkcyan;
  border-radius: 5px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  margin-left: 3px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  
}


.removebtn {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: var(--cui-dropdown-link-color, #4f5d73);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  & :hover {
    background-color: red !important;
  }
}

.btn-primary {
  --cui-btn-hover-bg: #dcdfe3 !important;
}

html:not([dir="rtl"]) .wrapper {
  
  padding-left: var(--cui-sidebar-occupy-start, 0);
}

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  color: var(--cui-footer-color, rgba(44, 56, 74, 0.95));
  background: white;
  border-top: var(--cui-footer-border-width, 1px) solid var(--cui-footer-border-color, #d8dbe0);
}

.dropdown-menu.show {
  display: block;
  margin-left: 10px;
}

img,
svg {
  vertical-align: middle;
 
}

.filterbtn {
  width: 53px;
  background: none;
  border: none;
}

.viewprofile {
 
  width: 248px;
  height: 100px;
  
  border-radius: 70px;
}

.header .container:first-child,
.header .container-fluid:first-child,
.header .container-sm:first-child,
.header .container-md:first-child,
.header .container-lg:first-child,
.header .container-xl:first-child,
.header .container-xxl:first-child {
  min-height: 3rem;
  background-color: darkcyan;
}

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  background-color: darkcyan;
}

.email-validate {
 

  margin-left: 1px;
  font-size: 11px;
  color: red;
  margin-top: -28px;
}

.email-validate1 {
 

  font-size: 12px;
  color: red;
  margin-top: -36px;
}

.email-validate3 {
  margin-left: 1px;
  font-size: 11px;
  color: red;
  margin-top: -20px;
}

.resetbutton {
  margin-top: 30px;
 
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  gap: 10px;
  width: 100%;
  margin-left: 0;
 
  height: 48px;
  

  

  background: #006EFF;
  border-radius: 8px;
}

.resetpasswordmessage {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
 

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;

  color: #727A83;
}

.text-muted {
  margin-top: 0;
  margin-bottom: 0rem;
}

.header.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1029;
  background-color: unset;
}

.header-divider {
  flex-basis: calc(100% + 1rem);
  height: 0;
  margin: 0.5rem -0.5rem;
  border-top: none !important;
  border-bottom: none !important;
}

.allbutton {
  background-color: darkcyan !important;

}


.form-select {
  display: block;
  width: 10rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  color: #768192;
  line-height: 1.5;
  background-color: var(--cui-form-select-bg, #fff);
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  margin-left: 18rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  appearance: none;
}

.tablestyle {
  border: 1px solid black;
}

.catTableItem {
  font-family: "Poppins", sans-serif;
  font: bolder;
}

th {
  vertical-align: middle;
  overflow: hidden;
  font-family: Poppins;
}

.d-inline {
  display: inline !important;
  font-size: 12px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}


.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  color: #768192;
  background-color: #fff;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


table,
th,
td {
  border: 1px solid rgb(223, 219, 219) !important;
  border-collapse: collapse !important;



}

.table-class {
  text-align: center;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #d8dbe0;
}

th {
  vertical-align: middle;
  overflow: hidden;
  font-family: Poppins;
}

td {
  font-size: 12px;
  text-align: center !important;
}

tbody {
  display: table-row-group !important;
  vertical-align: middle !important;
  border-color: inherit !important;
}

th {
  text-align: center !important;
  font-family: Poppins;
}

.input-group .btn {
  z-index: 0;
}

.carduser {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.noheader {
  min-width: 0 !important;
  padding: 0 !important;
}

.action-btn-bg {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 48px;
  left: 62.85%;
  right: 9.38%;
  top: calc(50% - 24px + 146px);
  color: white;
  width: 20rem;
  background: #006eff;
  border-radius: 8px;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.input-group {
  height: 2.2rem;
  width: 17.85rem;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: stretch;
  align-items: stretch;

}

.postViewTd {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 900px;
}

.postViewTd1 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 900;
}

.postViewTd2 {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 900;
}

.text-muted {
  /* --cui-text-opacity: 1; */
  /* color: rgba(44, 56, 74, 0.38) !important; */
  color: black !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  font-family: Urbanist;
  font-style: normal;
}



.postdetailveiwimg {
  margin-bottom: 1rem;
  width: 296px;
  height: 267px;
  box-sizing: border-box;
  background: rgba(196, 196, 196, 0.12);

  border-radius: 10px;
  margin-top: 2rem;
}

.nodatafound {
  text-align: center;
  font-size: 1.5rem;
  font-family: revert;
  font-weight: bold;
}




.c-avatar-img {
  margin-bottom: -17px;
  width: 11rem;
  height: 11rem;
  border-radius: 50rem;
  margin-top: 2rem;
}


.c-avatar-img1 {
  width: 3rem;
  height: 3rem;
  border-radius: 33px;
}


.user-management-padination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
}

.fontawesomeicon-inline {
  cursor: pointer;
  margin-right: 6px;
}

.user-management-td-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.cmodal-delete {
  background: darkcyan;
  color: #dcdfe3;
}

.margin-top-0rem {
  margin-top: 0rem;
}

.marginbottom-2rem {
  margin-bottom: 2rem;
}


.margin-left-2rem {
  margin-left: 2rem;
}

.display-flex {
  display: flex;
}

.flex-colume-5rem {
  display: flex;
  flex-direction: column;
  margin-left: -5rem;
}

.colume-flex {
  display: flex;
  flex-direction: column;
}

.user-management-ccol {
  margin-bottom: 1rem;
  display: flex;
  height: 2.2rem;
  justify-content: space-between;
}

.width-600 {
  width: 600;
}

.user-management-cinputgrouptext {
  background-color: teal;
  color: white;
  height: 2.2rem;
}

.user-management-input {
  max-width: 15rem;
  height: 100%;
  margin-right: 5;
}

.display-marginright10 {
  display: flex;
  margin-right: 10;
}

.user-management-cbutton {
  background-color: teal;
  color: white;
  height: 2.19rem;
  margin-right: 4;
  font-size: 0.875rem;
}


.user-management-cbutton-reset {
  background-color: teal;
  color: white;
  height: 2.19rem;
  font-size: 0.875rem;
}

.user-management-sub-cbutton-marginright {
  background-color: teal;
  color: white;
  height: 2.19rem;
  margin-right: 4;
  font-size: 0.875rem;
  margin-right: 10;
}

.user-management-cinputgroup {
  height: 2.2rem;
  max-width: 300;
  width: 100%;
}

.user-management-cinputgrouptext-filter {
  border-radius: 2px;
  background-color: #008080;
  color: #fff;
  height: "2.2rem",
}

.user-management-cinputgrouptext-filter_s {
  border-radius: 2px;
  background-color: #008080;
  color: #fff;
}

.user-management-cinputgrouptext-filter2 {
  border-radius: 2px;
  background-color: #008080;
  color: #fff;
  height: "100%",
}


.height-22rem {
  height: 2.2rem
}

.flex-1rem {
  margin-bottom: 1rem;
  display: flex;
}

.width-marginright {
  width: 100%;
  margin-right: 10px;
}

.user-img {
  margin-bottom: -23px;
  width: 4rem;
  height: 4rem;
}

.height-40vh {
  height: 40vh;
}

.basic-frame {
  font-family: Lato;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scroll-0 {
  padding: 0;
  overflow: scroll;
}

.margin-left-3rem {
  margin-top: 3rem;
}

.flex-center-auto {
  display: flex;
  justify-content: center;
  overflow: auto;
}


.margin-left-top {
  margin-left: 6rem;
  margin-top: 12px;

}

.margin-top-left {
  margin-top: 2rem;
  margin-left: -6rem;
}

.margin-left-top-bottom {
  margin-left: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.subscriptionbox-img {
  display: flex;
  margin-left: 11;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.postion-relative {
  position: relative;
}

.display-width-flex-100 {
  display: flex;
  width: 100%;
}

.width-height-1785rem-22rem {
  width: 17.85rem;
  height: 2.2rem;
}

.width-margin-right-1785rem-10rem {
  max-width: 17.85rem;
  margin-right: 10px;
}

.flex-5px-10px {
  display: flex;
  margin-top: 5px;
  margin-right: 10px;
}


.height-219rem {
  height: 2.19rem;
}

.height-maxwidth-width {
  height: 2.2rem;
  max-width: 340px;
  width: 100%;
}

.post-manangement-cinputgroup {
  margin-right: 10px;
  height: 2.2rem;
  max-width: 340px;
  width: 100%;
}

.maxwidth-height {
  max-width: 15rem;
  height: 100%;
}

.margin-bottom-display-justifycontent {
  margin-bottom: 1rem;
  display: flex;
  justify-content: left;
}


.flex-7rem {
  display: flex;
  margin-left: -7rem;
}

.justify-content-margin-top-bottom {
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 0rem
}

.center-grid {
  justify-content: center;
  display: grid;
}

.width-67 {
  width: 67%;
}

.margintop-28 {
  margin-top: 28%;
}

.margin-bottom-20 {
  margin-bottom: 20;
}


.cursor-pointer {
  cursor: pointer;
}

.flex-row-50 {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px
}

.margin-bottom-width-height {
  margin-bottom: -23px;
  width: 6rem;
  height: 8rem;
}