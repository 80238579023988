


.text-medium-emphasis{
width: 100%;
height: 48px;
left: 929px;
top: 248px;

font-family: 'Urbanist';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
display: flex;
align-items: flex-end;
letter-spacing: 0.01em;

color: #2367b4;
}

.erorrs{
    font-size: 14px;
    color: red;
    margin-top: -20px;
}

