.sample {
  background-color: #ffffff;
}

.background {
  background-image: url("../assets/images/loginscreen.png");
  height: 100%;
}
.background1 {
  background-image: url("../assets/images/loginscreen.png");
  height: 100%;
  width: 100vh;
}

.text-medium-emphasi {
  left: 905px;
  top: 230px;

  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;


  color: #192252;
}

.text-medium-emphasis {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  display: flex;
  align-items: flex-end;


  color: #848fac;
}

.col-md-8 {
  flex: 0 0 auto;
  width: 130.666667%;
}


.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  color: var(--cui-card-color, unset);
}

.title {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #35494d;
}

.text-rights {
 
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
 
  color:blue;
}

.submitlogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  height: 48px;
  left: 62.85%;
  right: 9.38%;
  top: calc(50% - 48px / 2 + 146px);
   color: white;
 
 width: 100%;
  background: #006eff;
  border-radius: 8px;
}
.forgot{
    margin-bottom: 1rem;
    width: 100%;
    align-items:flex-end;
    text-align: right;
}

.errors{
    font-size: 14px;
    color: red;
    margin-top: -30px;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: var(--cui-gutter-x, 0.75rem);
  padding-left: 0 ; 
  margin-right: auto;
  margin-left: auto;
}

