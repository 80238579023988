
.remove-post-btn{


    width: 233px;
    height: 40px;
    position: relative;
    left: 46%;
    color: white;
    background: darkcyan;
    border-radius: 5px;
}


.spinner-div{
        margin-top: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
}


.post-mini-view{
    width: 10rem !important;
    height: 10rem;
    padding: 1rem;
}
.post-filter-btns{
   
    justify-content: space-between;
}

.no-post-div{
    text-align: center;
    padding:2rem
}

.report-count-block{
  width: 10rem;
}
.report-count-block > div {
    margin-right: 0.5rem;
}